<template>
  <div>
    <b-card
      class="mt-4 border-0"
      body-class="p-2 bg-gray-200 text-center"
    >
      <b-row>
        <b-col md="4" ld="4">
          <b-button
            variant="info"
            size="lg"
            @click="buyProduct(1)"
          >
            Acquista report
          </b-button>
        </b-col>
        <b-col md="4" ld="4">
          <b-button
            variant="info"
            size="lg"
            @click="buyProduct(2)"
          >
            Acquista Visure
          </b-button>
        </b-col>
        <b-col md="4" ld="4">
          <b-button
            variant="info"
            size="lg"
            @click="buyProduct(3)"
          >
            Acquista Bilanci
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ProfileActions',
  components: { },
  props: {
    changeTab: Function,
  },
  computed: {
  },
  methods: {
    buyProduct(tab) {
      if (this.$props.changeTab) {
        this.$props.changeTab(tab);
      }
    },
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>

</style>
